/**
 * This template is used to render post tag index page
 *
 * @flow
 */
import React from 'react';
import { graphql } from 'gatsby';

import type { Props } from '../components/PostArchive';
import PostArchive from '../components/PostArchive';

const PostTagTemplate = ({ pageContext, data }: Props) => (
  <PostArchive
    pageContext={pageContext}
    data={data}
  />
);

export default PostTagTemplate;

export const pageQuery = graphql`
  query postTagTemplateQuery($skip: Int!, $limit: Int!, $tag: String!) {
    posts: allWordpressPost(
      sort: {fields: date, order: DESC}
      skip: $skip
      limit: $limit
      filter: {tags: {elemMatch: {id: {eq: $tag}}}}
    ) {
      ...WordPressPostConnection
    }
    allTags: allWordpressTag(sort: {fields: count, order: DESC}, filter: {count: {gt: 0}}) {
      edges {
        node {
          id
          path
          name
        }
      }
    }
    allCategories: allWordpressCategory(sort: {fields: count, order: DESC}, filter: {count: {gt: 0}}) {
      edges {
        node {
          id
          path
          name
        }
      }
    }
  }
`;
